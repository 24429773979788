/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-15",
    versionChannel: "nightly",
    buildDate: "2024-07-15T00:05:38.517Z",
    commitHash: "bed5a2fd86c4fc38a5273e246af992047ca565f1",
};
